import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Terminal: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.75654 15.5834C3.3814 15.5834 3.06432 15.4538 2.80529 15.1946C2.54626 14.9354 2.41675 14.6181 2.41675 14.2427V5.75065C2.41675 5.37523 2.54626 5.05905 2.80529 4.8021C3.06432 4.54516 3.3814 4.41669 3.75654 4.41669H16.2436C16.6188 4.41669 16.9358 4.54627 17.1949 4.80544C17.4539 5.0646 17.5834 5.38189 17.5834 5.75731V14.2494C17.5834 14.6248 17.4539 14.941 17.1949 15.1979C16.9358 15.4549 16.6188 15.5834 16.2436 15.5834H3.75654ZM3.75654 14.5H16.2436C16.3078 14.5 16.3665 14.4733 16.4199 14.4198C16.4733 14.3665 16.5001 14.3077 16.5001 14.2436V7.00002H3.50008V14.2436C3.50008 14.3077 3.52682 14.3665 3.58029 14.4198C3.63362 14.4733 3.69237 14.5 3.75654 14.5ZM6.25008 13.2036L5.48404 12.4375L7.17154 10.75L5.48404 9.06252L6.25008 8.29648L8.70362 10.75L6.25008 13.2036ZM10.2084 13.2917V12.2084H14.7917V13.2917H10.2084Z"
      fill="currentColor"
    />
  </svg>
);

export default Terminal;
