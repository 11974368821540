import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  type FunctionComponent,
} from 'react';

import { cn } from '@pxui/lib/utils';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import { IconProps } from '../icons';

const layoutClasses = 'relative flex items-center outline-none';
const spacingClasses = 'px-2 py-1.5';
const sizingClasses = 'w-[260px] h-8';
const disabledClasses =
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50';

const iconLayoutClasses = 'w-5 h-5';
const iconSpacingClasses = 'ml-1';
const iconColorClasses = 'text-icon-primary fill-icon-primary';
const iconDisabledClasses = 'text-icon-disabled fill-icon-disabled';

export const DropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    icon?: FunctionComponent<IconProps>;
    inset?: boolean;
  }
>(({ className, inset, children, icon: Icon, ...props }, ref) => {
  const icon = Icon && (
    <Icon
      className={cn(
        iconLayoutClasses,
        iconSpacingClasses,
        props.disabled ? iconDisabledClasses : iconColorClasses,
      )}
    />
  );

  return (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={cn(
        'label-1 text-secondary cursor-pointer hover:state-hover select-none transition-colors',
        inset && 'pl-8',
        layoutClasses,
        spacingClasses,
        sizingClasses,
        disabledClasses,
        className,
      )}
      {...props}
    >
      {icon}
      {children}
    </DropdownMenuPrimitive.Item>
  );
});

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

export default DropdownMenuItem;
