import PolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkPLYReader from '@kitware/vtk.js/IO/Geometry/PLYReader';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkITKPolyDataReader from '@kitware/vtk.js/IO/Misc/ITKPolyDataReader';
import { ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import { Representation } from '@kitware/vtk.js/Rendering/Core/Property/Constants';
import axios from 'axios';

export interface VtkField {
  id: string;
  label: string;
  range: [number, number];
  scalarMode: ScalarMode;
}

export interface GeometricRepresentation {
  id: string;
  name: string;
  property: { edgeVisibility: boolean; representation: number };
}

export const GEOMETRIC_REPRESENTATIONS: GeometricRepresentation[] = [
  {
    id: 'surface',
    name: 'Surface',
    property: { edgeVisibility: false, representation: Representation.SURFACE },
  },
  {
    id: 'surface_width_edges',
    name: 'Surface with edges',
    property: { edgeVisibility: true, representation: Representation.SURFACE },
  },
  {
    id: 'wireframe',
    name: 'Wireframe',
    property: {
      edgeVisibility: false,
      representation: Representation.WIREFRAME,
    },
  },
  {
    id: 'points',
    name: 'Points',
    property: { edgeVisibility: false, representation: Representation.POINTS },
  },
];

export function getFieldsFromVtkPolydata(polydata: PolyData) {
  const fields: VtkField[] = [];

  if (!polydata) {
    return fields;
  }

  polydata
    .getPointData()
    .getArrays()
    .forEach((a: any) => {
      fields.push({
        id: a.getName(),
        label: `(pointData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA,
      });
    });

  polydata
    .getCellData()
    .getArrays()
    .forEach((a) => {
      fields.push({
        id: a.getName(),
        label: `(cellData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_CELL_FIELD_DATA,
      });
    });

  polydata
    .getFieldData()
    .getArrays()
    .forEach((a) => {
      fields.push({
        id: a.getName(),
        label: `(fieldData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_FIELD_DATA,
      });
    });

  return fields;
}

export async function parseArrayBufferToPolydata(
  arrayBuffer: ArrayBuffer,
  fileName: string,
): Promise<PolyData> {
  let reader = null;

  if (fileName.endsWith('.stl')) {
    reader = vtkSTLReader.newInstance();
  } else if (fileName.endsWith('.ply')) {
    reader = vtkPLYReader.newInstance();
  } else {
    //  legacy or xml based VTK formtas
    reader = vtkITKPolyDataReader.newInstance();
    reader.setFileName(fileName);
  }

  await reader.parseAsArrayBuffer(arrayBuffer);
  return reader.getOutputData(0);
}

export async function fetchArrayBufferFromS3(url: string) {
  //  @TODO - should reuse services/downloadFromS3
  const resp = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  return resp.data;
}

export function parseFileNameFromPresignedUrl(url: string): string | null {
  try {
    const parsedUrl = new URL(url);
    const { pathname } = parsedUrl;
    const fileNameMatch = pathname.match(/\/([^/]+)$/);

    if (fileNameMatch) {
      const fileName = fileNameMatch[1];
      return fileName;
    }

    return null;
  } catch (error) {
    console.error('Invalid presigned URL', error);
    return null;
  }
}
