import { useEffect, useState } from 'react';
import type { ChangeEvent, FC } from 'react';

import vtkITKPolyDataReader from '@kitware/vtk.js/IO/Misc/ITKPolyDataReader';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import { Spinner } from '@pxui/components/ui';
import config from 'itk/itkConfig';
import readPolyDataArrayBuffer from 'itk/readPolyDataArrayBuffer';

import MeshViewerUI from './MeshViewerUI';
import {
  parseArrayBufferToPolydata,
  getFieldsFromVtkPolydata,
  VtkField,
  fetchArrayBufferFromS3,
  parseFileNameFromPresignedUrl,
  GEOMETRIC_REPRESENTATIONS,
} from './utils';
import VtkScene from './VtkScene';

interface MeshViewerProps {
  url: string;
}

config.itkModulesPath = `/itk`;
vtkITKPolyDataReader.setReadPolyDataArrayBufferFromITK(readPolyDataArrayBuffer);

const MeshViewer: FC<MeshViewerProps> = ({ url }) => {
  const [colorPreset, setColorPreset] = useState(
    // eslint-disable-next-line no-magic-numbers
    vtkColorMaps.rgbPresetNames[20],
  );

  const [representation, setRepresentation] = useState(
    GEOMETRIC_REPRESENTATIONS[0],
  );
  const [fields, setFields] = useState<VtkField[]>([]);
  const [selectedField, setSelectedField] = useState<VtkField | undefined>();
  const [vtkPolydata, setVtkPolyData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSelectedFieldChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const activeField = fields.find((field) => field.id === evt.target.value);
    if (!activeField) {
      return;
    }

    setSelectedField(activeField);
  };

  const handleColorSchemeChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setColorPreset(evt.target.value);
  };

  const handleRepresentationChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const id = evt.target.value;
    const selectedRepresentation = GEOMETRIC_REPRESENTATIONS.find(
      (repr) => repr.id === id,
    );
    if (selectedRepresentation) {
      setRepresentation(selectedRepresentation);
    }
  };

  useEffect(() => {
    async function fetchVtkAsArrayBuffer(vtkUrl: string) {
      setIsLoading(true);

      try {
        const fileName = parseFileNameFromPresignedUrl(url);
        if (!fileName) {
          throw new Error(
            `Couldn't parse presigned URL to get file name: ${url}`,
          );
        }

        const arrayBuffer = await fetchArrayBufferFromS3(vtkUrl);

        const polydata = await parseArrayBufferToPolydata(
          arrayBuffer,
          fileName,
        );
        if (polydata) {
          setVtkPolyData(polydata);
        }

        const localFields = getFieldsFromVtkPolydata(polydata);
        setFields(localFields);
        if (localFields.length > 0) {
          setSelectedField(localFields[0]);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }

      setIsLoading(false);
    }
    if (url) {
      fetchVtkAsArrayBuffer(url);
    }
  }, [url]);

  if (isLoading) {
    return (
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        <Spinner className="w-10 h-10" />
      </div>
    );
  }

  return (
    <div className="relative w-full h-full">
      {vtkPolydata && (
        <VtkScene
          polydata={vtkPolydata}
          representation={representation}
          selectedField={selectedField}
          colorPreset={colorPreset}
        />
      )}
      <MeshViewerUI
        colorPreset={colorPreset}
        representation={representation}
        selectedField={selectedField}
        fields={fields}
        onRepresentationChange={handleRepresentationChange}
        onSelectedFieldChange={handleSelectedFieldChange}
        onColorSchemeChange={handleColorSchemeChange}
      />
    </div>
  );
};

export default MeshViewer;
