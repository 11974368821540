import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

const AppBarNavigationWrapper = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => (
  <div className={cn('flex', className)} ref={ref} {...props}>
    {children}
  </div>
));

AppBarNavigationWrapper.displayName = 'AppBarNavigationWrapper';

export default AppBarNavigationWrapper;
