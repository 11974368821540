import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Logout: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.75654 16.5834C4.3814 16.5834 4.06432 16.4538 3.80529 16.1948C3.54626 15.9358 3.41675 15.6187 3.41675 15.2436V4.75648C3.41675 4.38134 3.54626 4.06426 3.80529 3.80523C4.06432 3.5462 4.3814 3.41669 4.75654 3.41669H10.008V4.50002H4.75654C4.69237 4.50002 4.63362 4.52676 4.58029 4.58023C4.52682 4.63356 4.50008 4.69231 4.50008 4.75648V15.2436C4.50008 15.3077 4.52682 15.3665 4.58029 15.4198C4.63362 15.4733 4.69237 15.5 4.75654 15.5H10.008V16.5834H4.75654ZM13.6924 12.8911L12.9103 12.1329L14.5017 10.5417H8.08029V9.45835H14.5017L12.9103 7.8671L13.6924 7.10898L16.5834 10L13.6924 12.8911Z"
      fill="currentColor"
    />
  </svg>
);

export default Logout;
