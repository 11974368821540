import { RouteObject } from 'react-router-dom';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

export const routes: RouteObject[] = [
  {
    index: true,
    lazy: lazyLoadHelper(() => import('./LauncherPage/LauncherPage')),
  },
  {
    lazy: lazyLoadHelper(() => import('./EmbeddedAppPage/EmbeddedAppPage')),
    path: '/app/:id',
  },
  {
    lazy: lazyLoadHelper(() => import('./ErrorPage/ErrorPage')),
    path: '*', // 404 page
  },
];

export default routes;
