import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Key: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.90469 5.99999C7.90469 5.52985 8.06705 5.13242 8.39177 4.8077C8.7165 4.48298 9.11393 4.32062 9.58407 4.32062C10.0542 4.32062 10.4516 4.48298 10.7764 4.8077C11.1011 5.13242 11.2634 5.52985 11.2634 5.99999C11.2634 6.47013 11.1011 6.86756 10.7764 7.19228C10.4516 7.517 10.0542 7.67936 9.58407 7.67936C9.11393 7.67936 8.7165 7.517 8.39177 7.19228C8.06705 6.86756 7.90469 6.47013 7.90469 5.99999ZM9.58407 19.4952L6.64511 16.29L7.80677 14.6602L6.62907 13.2404L8.00073 11.3846V10.3269C7.06698 9.94659 6.33358 9.3745 5.80052 8.61061C5.26733 7.84673 5.00073 6.97652 5.00073 5.99999C5.00073 4.7286 5.44677 3.64686 6.33886 2.75478C7.23094 1.8627 8.31268 1.41666 9.58407 1.41666C10.8555 1.41666 11.9372 1.8627 12.8293 2.75478C13.7214 3.64686 14.1674 4.7286 14.1674 5.99999C14.1674 6.86541 13.9404 7.6627 13.4864 8.39186C13.0322 9.12103 12.4259 9.6827 11.6674 10.0769V17.4119L9.58407 19.4952ZM6.08407 5.99999C6.08407 6.89638 6.37066 7.67173 6.94386 8.32603C7.51705 8.98048 8.23045 9.35527 9.08407 9.45041V11.7564L8.02469 13.2067L9.17386 14.6058L8.02948 16.2292L9.6049 17.9296L10.5841 16.9712V9.34624C11.2988 9.13999 11.8941 8.727 12.3701 8.10728C12.8461 7.4877 13.0841 6.78527 13.0841 5.99999C13.0841 5.02777 12.7438 4.20138 12.0632 3.52082C11.3827 2.84027 10.5563 2.49999 9.58407 2.49999C8.61184 2.49999 7.78545 2.84027 7.1049 3.52082C6.42434 4.20138 6.08407 5.02777 6.08407 5.99999Z"
      fill="currentColor"
    />
  </svg>
);

export default Key;
