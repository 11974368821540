import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

import { NavigationMenu } from './navigationMenu';
import { NavigationMenuContent } from './navigationMenuContent';
import { NavigationMenuIndicator } from './navigationMenuIndicator';
import { NavigationMenuList } from './navigationMenuList';
import {
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from './navigationMenuTrigger';
import { NavigationMenuViewport } from './navigationMenuViewport';

const NavigationMenuItem = NavigationMenuPrimitive.Item;
const NavigationMenuLink = NavigationMenuPrimitive.Link;

export {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuContent,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuIndicator,
  NavigationMenuViewport,
};
