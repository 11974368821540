import { useRef, useEffect } from 'react';
import type { FC } from 'react';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

import PolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import { ColorMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import { RGBColor, RGBAColor } from '@kitware/vtk.js/types';

import { VtkField, GeometricRepresentation } from './utils';

export const DEFAULT_VTK_SCENE_BACKGROUND_COLOR: RGBColor = [
  // eslint-disable-next-line no-magic-numbers
  0.047, 0.055, 0.067,
];

interface VtkSceneProps {
  backgroundColor?: RGBColor | RGBAColor;
  colorPreset: string;
  polydata: PolyData;
  representation: GeometricRepresentation;
  selectedField?: VtkField;
}

const VtkScene: FC<VtkSceneProps> = ({
  backgroundColor,
  colorPreset,
  polydata,
  representation,
  selectedField,
}) => {
  const vtkContainerRef = useRef(null);
  const context = useRef<{
    actor: vtkActor;
    fullScreenRenderer: vtkFullScreenRenderWindow;
    mapper: vtkMapper;
    renderWindow: vtkRenderWindow;
    renderer: vtkRenderer;
  } | null>(null);

  useEffect(() => {
    if (!context.current && vtkContainerRef.current) {
      const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
        container: vtkContainerRef.current,
      });
      fullScreenRenderer.setBackground(
        backgroundColor || DEFAULT_VTK_SCENE_BACKGROUND_COLOR,
      );

      const mapper = vtkMapper.newInstance();
      const actor = vtkActor.newInstance();
      actor.setMapper(mapper);

      const renderer = fullScreenRenderer.getRenderer();
      const renderWindow = fullScreenRenderer.getRenderWindow();

      renderer.addActor(actor);
      renderer.resetCamera();

      renderWindow.render();

      context.current = {
        actor,
        fullScreenRenderer,
        mapper,
        renderWindow,
        renderer,
      };
    }

    return () => {
      if (context.current) {
        const { fullScreenRenderer, actor, mapper } = context.current;
        actor.delete();
        mapper.delete();
        fullScreenRenderer.delete();
        context.current = null;
      }
    };
  }, [vtkContainerRef, backgroundColor]);

  useEffect(() => {
    if (context.current && context.current.fullScreenRenderer) {
      context.current.fullScreenRenderer.setBackground(
        backgroundColor || DEFAULT_VTK_SCENE_BACKGROUND_COLOR,
      );
    }
  }, [backgroundColor]);

  useEffect(() => {
    if (context.current && context.current.actor) {
      const { actor, renderWindow } = context.current;
      actor
        .getProperty()
        .setRepresentation(representation.property.representation);
      actor
        .getProperty()
        .setEdgeVisibility(representation.property.edgeVisibility);
      renderWindow.render();
    }
  }, [representation]);

  useEffect(() => {
    const { mapper, renderWindow } = context.current || {};
    if (mapper && renderWindow && selectedField) {
      if (!selectedField) {
        return;
      }

      const { range, scalarMode, id: colorByArrayName } = selectedField;

      const preset = vtkColorMaps.getPresetByName(colorPreset);
      const lookupTable = vtkColorTransferFunction.newInstance();
      lookupTable.applyColorMap(preset);
      lookupTable.setRange(...range);

      mapper.set(
        {
          colorByArrayName,
          //  these might need to change depending on the mesh
          colorMode: ColorMode.MAP_SCALARS,

          lookupTable,
          scalarMode,
          useLookupTableScalarRange: true,
        },
        true,
      );

      renderWindow.render();
    }
  }, [selectedField, colorPreset]);

  useEffect(() => {
    const { mapper, renderer, renderWindow } = context.current || {};
    if (polydata && mapper && renderer && renderWindow) {
      mapper.setInputData(polydata);

      renderer.resetCamera();
      renderWindow.render();
    }
  }, [polydata]);

  return <div ref={vtkContainerRef} className="relative w-full h-full" />;
};

export default VtkScene;
