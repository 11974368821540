import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const Dot: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx="10"
      cy="10"
      r="5"
      fill="#879A00"
      stroke="#191C20"
      strokeWidth="2"
    />
  </svg>
);

export default Dot;
