import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBarLink,
  AppBarNavigation,
  AppName,
  HelpIcon,
  Button,
  AppsIcon,
  AppBar,
  AppBarNavigationWrapper,
  AppBarUserProfile,
} from '@pxui/components/ui';

import { AppbarContext } from '@contexts/AppbarContext';

import { DOCS_URL } from '@constants/common';

import UserProfile from './UserProfile';

const Navbar: FC = () => {
  const { user, logout } = useAuth0();
  const appBarState = useContext(AppbarContext);
  const navigate = useNavigate();

  const { appName, navigationLinks } = appBarState;

  const handleOnClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <AppBar className="fixed top-0 w-full pl-2 h-[50px]">
      <AppBarNavigationWrapper>
        <Button
          icon={AppsIcon}
          layout="iconOnly"
          variant="ghost"
          onClick={handleOnClick}
        />
        <AppName appName={appName} prefix="PX" />
        <AppBarNavigation>
          {navigationLinks.map(({ label, path }) => (
            <AppBarLink key={path} linkText={label} linkTo={path} />
          ))}
        </AppBarNavigation>
      </AppBarNavigationWrapper>
      <AppBarUserProfile>
        <a href={DOCS_URL} aria-label="PhysicsX Documentation">
          <Button layout="iconOnly" variant="ghost" icon={HelpIcon} />
        </a>
        <UserProfile user={user} logoutHandler={logout} />
      </AppBarUserProfile>
    </AppBar>
  );
};

export default Navbar;
