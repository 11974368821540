import type { FC } from 'react';

import { IconSize, type IconProps } from './types';

const CodeBlock: FC<IconProps> = ({ className, size = 'default' }) => (
  <svg
    width={IconSize[size]}
    height={IconSize[size]}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.00008 12.2115L8.77404 11.4454L7.32862 10L8.76612 8.56252L7.99216 7.79648L5.78862 10L8.00008 12.2115ZM12.0001 12.2115L14.2115 10L12.0001 7.78856L11.2261 8.5546L12.6715 10L11.2261 11.4454L12.0001 12.2115ZM4.75654 16.5834C4.3814 16.5834 4.06432 16.4538 3.80529 16.1948C3.54626 15.9358 3.41675 15.6187 3.41675 15.2436V4.75648C3.41675 4.38134 3.54626 4.06426 3.80529 3.80523C4.06432 3.5462 4.3814 3.41669 4.75654 3.41669H15.2436C15.6188 3.41669 15.9358 3.5462 16.1949 3.80523C16.4539 4.06426 16.5834 4.38134 16.5834 4.75648V15.2436C16.5834 15.6187 16.4539 15.9358 16.1949 16.1948C15.9358 16.4538 15.6188 16.5834 15.2436 16.5834H4.75654ZM4.75654 15.5H15.2436C15.3078 15.5 15.3665 15.4733 15.4199 15.4198C15.4733 15.3665 15.5001 15.3077 15.5001 15.2436V4.75648C15.5001 4.69231 15.4733 4.63356 15.4199 4.58023C15.3665 4.52676 15.3078 4.50002 15.2436 4.50002H4.75654C4.69237 4.50002 4.63362 4.52676 4.58029 4.58023C4.52682 4.63356 4.50008 4.69231 4.50008 4.75648V15.2436C4.50008 15.3077 4.52682 15.3665 4.58029 15.4198C4.63362 15.4733 4.69237 15.5 4.75654 15.5Z"
      fill="currentColor"
    />
  </svg>
);

export default CodeBlock;
