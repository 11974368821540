import type { ChangeEvent, FC } from 'react';

import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';

import {
  VtkField,
  GEOMETRIC_REPRESENTATIONS,
  GeometricRepresentation,
} from './utils';

interface MeshViewerUIProps {
  colorPreset: string;
  fields: VtkField[];
  onColorSchemeChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
  onRepresentationChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
  onSelectedFieldChange: (evt: ChangeEvent<HTMLSelectElement>) => void;
  representation: GeometricRepresentation;
  selectedField?: VtkField;
}

const MeshViewerUI: FC<MeshViewerUIProps> = ({
  colorPreset,
  representation,
  selectedField,
  fields,
  onRepresentationChange,
  onSelectedFieldChange,
  onColorSchemeChange,
}) => {
  return (
    <div
      className="flex flex-col label-1 text-primary"
      style={{
        left: '25px',
        padding: '12px',
        position: 'absolute',
        top: '25px',
      }}
    >
      <label htmlFor="colorPreset">
        Color schema:
        {colorPreset && (
          <select
            id="colorPreset"
            className="bg-transparent px-2 py-1.5"
            value={colorPreset}
            onInput={onColorSchemeChange}
          >
            {vtkColorMaps.rgbPresetNames.map((presetName) => {
              return (
                <option key={presetName} value={presetName}>
                  {presetName}
                </option>
              );
            })}
          </select>
        )}
      </label>
      <label htmlFor="representation">
        Representation:
        <select
          className="bg-transparent px-2 py-1.5"
          value={representation.id}
          onInput={onRepresentationChange}
        >
          {GEOMETRIC_REPRESENTATIONS.map((repr) => {
            return (
              <option key={repr.id} value={repr.id}>
                {repr.name}
              </option>
            );
          })}
        </select>
      </label>
      <label htmlFor="selectedField">
        Field to display:
        {selectedField && (
          <select
            id="selectedField"
            className="bg-transparent px-2 py-1.5"
            value={selectedField.id}
            onInput={onSelectedFieldChange}
          >
            {fields.map((field) => {
              return (
                <option
                  key={field.id}
                  value={field.id}
                  data-scalar-mode={field.scalarMode}
                >
                  {field.label}
                </option>
              );
            })}
          </select>
        )}
      </label>
    </div>
  );
};

export default MeshViewerUI;
